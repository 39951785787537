import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import ReactTooltip from "react-tooltip";

import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import "./Skills.scss";

function Skills() {
  const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const query = '*[_type == "experiences"]';
    const skillsQuery = '*[_type == "skills"]';

    // client.fetch(query).then((data) => {
      let data ={
        "ms": 15,
        "query": "*[_type == \"experiences\"]",
        "result": [
            {
                "_createdAt": "2022-03-25T17:04:43Z",
                "_id": "20e82534-f455-4e50-a857-28b714b23f65",
                "_rev": "i4tlC7j5gfT32K0OfQRHXW",
                "_type": "experiences",
                "_updatedAt": "2022-03-25T17:04:43Z",
                "works": [
                    {
                        "_key": "2efc864b44ac",
                        "_type": "workExperience",
                        "company": "待定...",
                        "desc": "希望自己能够有好运～",
                        "name": "开启求职之路"
                    }
                ],
                "year": "2022"
            },
            {
                "_createdAt": "2022-03-16T08:31:32Z",
                "_id": "71e4446e-8eda-4cba-8be9-b4142e779750",
                "_rev": "fPSaui3CWkXGhqNMf39onK",
                "_type": "experiences",
                "_updatedAt": "2022-03-25T17:03:49Z",
                "works": [
                    {
                        "_key": "ca538b7022a5",
                        "_type": "workExperience",
                        "desc": "中间从事了一些工作，但空余时间仍然在学习，打磨技能",
                        "name": "仍然在自学中"
                    }
                ],
                "year": "2018"
            },
            {
                "_createdAt": "2022-03-16T08:17:24Z",
                "_id": "e6289d20-988f-4ae4-b8d3-a9489c0fd4b1",
                "_rev": "i4tlC7j5gfT32K0OfRWnhp",
                "_type": "experiences",
                "_updatedAt": "2022-03-26T13:31:13Z",
                "works": [
                    {
                        "_key": "c53bbe55f492",
                        "_type": "workExperience",
                        "desc": "加油冲～",
                        "name": "开启Web自学之旅"
                    },
                    {
                        "_key": "fbca24bca138",
                        "_type": "workExperience",
                        "company": "***",
                        "desc": "终于毕业了",
                        "name": "大学毕业"
                    }
                ],
                "year": "2016"
            }
        ]
    }
      setExperiences(data.result);
    // });

    // client.fetch(skillsQuery).then((data) => {
    data={
      "ms": 5,
      "query": "*[_type == \"skills\"]",
      "result": [
          {
              "_createdAt": "2022-03-25T16:48:39Z",
              "_id": "11f45b5c-6571-4b34-8e60-72b625430e6a",
              "_rev": "konqSPzeOcgJDWWuis7C3e",
              "_type": "skills",
              "_updatedAt": "2022-03-25T16:48:39Z",
              "bgColor": "#edf2f8",
              "icon": {
                  "_type": "image",
                  "asset": {
                      "_ref": "image-0032c1c2ec6f56e71af56862c5dff4e43d8e3c21-800x479-png",
                      "_type": "reference"
                  }
              },
              "name": "Next.js"
          },
          {
              "_createdAt": "2022-03-25T16:50:00Z",
              "_id": "13ee70f9-7020-4847-acb8-20dafec96de1",
              "_rev": "i4tlC7j5gfT32K0OfQQSzG",
              "_type": "skills",
              "_updatedAt": "2022-03-25T16:50:00Z",
              "bgColor": "#edf2f8",
              "icon": {
                  "_type": "image",
                  "asset": {
                      "_ref": "image-107574d887f1c07c9fbea0704de7f293e244a8f3-480x480-png",
                      "_type": "reference"
                  }
              },
              "name": "CSS"
          },
          {
              "_createdAt": "2022-03-25T16:49:17Z",
              "_id": "19444c3b-b6ea-4816-80f0-60549fc88797",
              "_rev": "i4tlC7j5gfT32K0OfQQRcY",
              "_type": "skills",
              "_updatedAt": "2022-03-25T16:49:17Z",
              "bgColor": "#edf2f8",
              "icon": {
                  "_type": "image",
                  "asset": {
                      "_ref": "image-5da5861e363165c1a83c7c34c3bc7cc31c889de3-820x820-png",
                      "_type": "reference"
                  }
              },
              "name": "Material UI"
          },
          {
              "_createdAt": "2022-03-16T07:51:23Z",
              "_id": "1cd42e3a-750c-4c94-9bf0-4aaba54317c3",
              "_rev": "TMxLPcgKYh2cjbuPGnREHx",
              "_type": "skills",
              "_updatedAt": "2022-03-16T09:25:29Z",
              "bgColor": "#edf2f8",
              "icon": {
                  "_type": "image",
                  "asset": {
                      "_ref": "image-91716227a0183f05eb4c2c846dda4ec6d3ea433c-480x480-png",
                      "_type": "reference"
                  }
              },
              "name": "Redux"
          },
          {
              "_createdAt": "2022-03-25T16:49:43Z",
              "_id": "27dac1f4-1d54-4643-8cce-50f21341c5fd",
              "_rev": "konqSPzeOcgJDWWuis7Odq",
              "_type": "skills",
              "_updatedAt": "2022-03-25T16:49:43Z",
              "bgColor": "#edf2f8",
              "icon": {
                  "_type": "image",
                  "asset": {
                      "_ref": "image-31f11147f89dbd855a9b948e2ce643ea2f41c0a9-480x480-png",
                      "_type": "reference"
                  }
              },
              "name": "HTML"
          },
          {
              "_createdAt": "2022-03-16T07:49:30Z",
              "_id": "38103ff8-6996-4e0c-b851-b19cfc136092",
              "_rev": "TMxLPcgKYh2cjbuPGnRWhe",
              "_type": "skills",
              "_updatedAt": "2022-03-16T09:26:50Z",
              "bgColor": "#edf2f8",
              "icon": {
                  "_type": "image",
                  "asset": {
                      "_ref": "image-34be54263e18fa2aea611c6b3b388b76e978e7d7-64x64-png",
                      "_type": "reference"
                  }
              },
              "name": "React"
          },
          {
              "_createdAt": "2022-03-16T07:50:29Z",
              "_id": "55fb9e03-6fb5-4fbf-a22a-05a99cdfb552",
              "_rev": "TN0tvfn1lXhPHZXOI5RsEj",
              "_type": "skills",
              "_updatedAt": "2022-03-16T09:26:28Z",
              "bgColor": "#edf2f8",
              "icon": {
                  "_type": "image",
                  "asset": {
                      "_ref": "image-66bbf9242b1ccffebb5d46f376f5036b527fad48-480x480-png",
                      "_type": "reference"
                  }
              },
              "name": "JavaScript"
          },
          {
              "_createdAt": "2022-03-25T16:48:09Z",
              "_id": "6959bbfd-42f8-4def-a1b2-e408bd667d6f",
              "_rev": "konqSPzeOcgJDWWuis6vso",
              "_type": "skills",
              "_updatedAt": "2022-03-25T16:48:09Z",
              "bgColor": "#edf2f8",
              "icon": {
                  "_type": "image",
                  "asset": {
                      "_ref": "image-3a4550a077a41dc6440ae8044a62824044a70c4f-2048x2048-png",
                      "_type": "reference"
                  }
              },
              "name": "Tailwind CSS"
          },
          {
              "_createdAt": "2022-03-25T16:51:09Z",
              "_id": "8e355a79-0438-4dae-8842-1a92383854ea",
              "_rev": "konqSPzeOcgJDWWuis7SZ4",
              "_type": "skills",
              "_updatedAt": "2022-03-25T16:51:09Z",
              "bgColor": "#edf2f8",
              "icon": {
                  "_type": "image",
                  "asset": {
                      "_ref": "image-652ac41e234436eee36dc83d73bf3f3c22e0be41-256x256-png",
                      "_type": "reference"
                  }
              },
              "name": "Sass"
          },
          {
              "_createdAt": "2022-03-25T16:54:46Z",
              "_id": "9b7bac86-f95d-4aa5-84cf-9ef02efecfbe",
              "_rev": "i4tlC7j5gfT32K0OfQQqRz",
              "_type": "skills",
              "_updatedAt": "2022-03-25T16:57:40Z",
              "bgColor": "#edf2f8",
              "icon": {
                  "_type": "image",
                  "asset": {
                      "_ref": "image-f1563294314712587fe2a517c427591868ee5be1-840x848-png",
                      "_type": "reference"
                  }
              },
              "name": "jQuery"
          },
          {
              "_createdAt": "2022-03-16T07:50:03Z",
              "_id": "ee6ee58b-3e46-48fb-81c5-f21e79ef8b1a",
              "_rev": "TMxLPcgKYh2cjbuPGnRTxo",
              "_type": "skills",
              "_updatedAt": "2022-03-16T09:26:39Z",
              "bgColor": "#edf2f8",
              "icon": {
                  "_type": "image",
                  "asset": {
                      "_ref": "image-6551e520e801ab656029527c09265981c06dce33-480x480-png",
                      "_type": "reference"
                  }
              },
              "name": "Figma"
          },
          {
              "_createdAt": "2022-03-16T07:50:46Z",
              "_id": "f5ff2cfa-3977-421b-88aa-d46aab73f8e4",
              "_rev": "TN0tvfn1lXhPHZXOI5Rq8n",
              "_type": "skills",
              "_updatedAt": "2022-03-16T09:26:19Z",
              "bgColor": "#edf2f8",
              "icon": {
                  "_type": "image",
                  "asset": {
                      "_ref": "image-a804a741fb26f6c236c73086a87cfc9f64106401-480x480-png",
                      "_type": "reference"
                  }
              },
              "name": "Git"
          }
      ]
  }
      setSkills(data.result);
    // });
  }, []);

  return (
    <>
      <h2 className="head-text">技能和经历</h2>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills.map((skill, id) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={id}
            >
              <div className="app__flex" style={{ backgroundColor: skill.bgColor }}>
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>
        <motion.div className="app__skills-exp">
          {experiences.map((experience, id) => (
            <motion.div className="app__skills-exp-item" key={id}>
              <div className="app__skills-exp-year">
                <p className="bold-text">{experience.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                {experience.works.map((work, workId) => (
                  <>
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      className="app__skills-exp-work"
                      data-tip
                      data-for={work.name}
                      key={workId}
                    >
                      <h4 className="bold-text">{work.name}</h4>
                      <p className="p-text">{work.company}</p>
                    </motion.div>
                    <ReactTooltip id={work.name} effect="solid" arrowColor="#fff" className="skills-tooltip">
                      {work.desc}
                    </ReactTooltip>
                  </>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </>
  );
}

export default AppWrap(MotionWrap(Skills, "app__skills"), "技能", "app__whitebg");
