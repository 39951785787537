import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import "./About.scss";
import { urlFor, client } from "../../client";
import { AppWrap, MotionWrap } from "../../wrapper";

function About() {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    // const query = '*[_type == "abouts"]';

    // client.fetch(query).then((data) => setAbouts(data));
    let data ={
      "ms": 16,
      "query": "*[_type == \"abouts\"]",
      "result": [
          {
              "_createdAt": "2022-03-15T14:08:06Z",
              "_id": "306fffef-6bd0-4bc7-ab9d-00a64412e9d1",
              "_rev": "konqSPzeOcgJDWWuipoalm",
              "_type": "abouts",
              "_updatedAt": "2022-03-24T15:51:52Z",
              "description": "我喜欢设计优美的UI/UX",
              "imgUrl": {
                  "_type": "image",
                  "asset": {
                      "_ref": "image-b62651ef34a9e54c354ee437545acc348fbf0505-1200x900-png",
                      "_type": "reference"
                  }
              },
              "title": "网页设计"
          },
          {
              "_createdAt": "2022-03-24T15:54:17Z",
              "_id": "65ce867f-0700-4e05-a326-bc40b5c885dc",
              "_rev": "konqSPzeOcgJDWWuipoppO",
              "_type": "abouts",
              "_updatedAt": "2022-03-24T15:54:42Z",
              "description": "正在努力学习中......",
              "imgUrl": {
                  "_type": "image",
                  "asset": {
                      "_ref": "image-90d71de50f2c6659156500e7a4d76a618e1fcd63-1200x900-png",
                      "_type": "reference"
                  }
              },
              "title": "后端开发"
          },
          {
              "_createdAt": "2022-03-15T14:11:38Z",
              "_id": "ce5c96a7-a594-43e5-a37a-64e38c8d8c21",
              "_rev": "fPSaui3CWkXGhqNMf3A4xo",
              "_type": "abouts",
              "_updatedAt": "2022-03-25T17:14:58Z",
              "description": "个人最喜欢也是目前最流行的前端框架",
              "imgUrl": {
                  "_type": "image",
                  "asset": {
                      "_ref": "image-65ed72deac647aa84f75c5af8f6284ab926931b0-1200x900-png",
                      "_type": "reference"
                  }
              },
              "title": "Vue开发"
          },
          {
              "_createdAt": "2022-03-15T05:54:01Z",
              "_id": "fbc9bcc8-1363-4c4b-90d6-996f5a4e03d0",
              "_rev": "fPSaui3CWkXGhqNMf3A6n2",
              "_type": "abouts",
              "_updatedAt": "2022-03-25T17:15:42Z",
              "description": "开发美丽且功能完善的网站是我的热情",
              "imgUrl": {
                  "_type": "image",
                  "asset": {
                      "_ref": "image-db53adca404b7410a2869db815e4ce77da2e7e53-1200x900-png",
                      "_type": "reference"
                  }
              },
              "title": "前端开发"
          }
      ]
  }
    setAbouts(data.result )
  }, []);

  return (
    <>
      <h2 className="head-text">
        {/* I Know That <span>Good Apps</span>
        <br /> means <span>Good Business</span> */}
        关于<span>我</span>
      </h2>

      <div className="app__profiles">
        {abouts
          .sort((a, b) => (a.description > b.description ? 1 : b.description > a.description ? -1 : 0))
          .map((about, index) => (
            <motion.div
              whileInView={{ opacity: 1 }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, type: "tween" }}
              className="app__profile-item"
              key={about.title + index}
            >
              <img src={urlFor(about.imgUrl)} alt="about.title" />
              <h2 className="bold-text" style={{ marginTop: 20 }}>
                {about.title}
              </h2>
              <p className="p-text" style={{ marginTop: 10 }}>
                {about.description}
              </p>
            </motion.div>
          ))}
      </div>
    </>
  );
}

export default AppWrap(MotionWrap(About, "app__about"), "关于", "app__whitebg");
